<template>
  <div>
    <van-nav-bar 
      title="立即購買" 
      right-text="關閉"
      @click-right="$router.push('/')"
    />
    <div class="main-section" v-html="shopContent">
    </div>
  </div>
  <Footer/>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

import {Toast} from 'vant'

import Footer from '@/components/Footer'

import { setUserLevel } from '@/api'

import { useRouter} from 'vue-router'

import { getSiteConfig } from '@/api'


const store = useStore()
const router = useRouter()

const shopContent = ref('')

        let res = await getSiteConfig()

        if(res.code===200){
          store.commit('setSiteConfig',res.data)    
        }else{
          Toast('系統錯誤')
          router.push('/home')
        }

        shopContent.value=store.state.config.shop_content

const setlevel = async (level)=>{
      // let res = await setUserLevel(level)
      // store.commit('user/setLevel',level)
      // Toast('設定成功')
      // router.push('/')
      // window.location.href = 'http://www.tggo.com.tw/book.cgi?user=omo'
}
</script>

<style lang="less" scoped>
.main-section{
	width: 100%;
}
.recommend {
	width: 100%;
	padding-top: 2%;
}
.recommend ul {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.recommend ul li {
	width: 48%;
	flex-direction: column;
	margin: 1%;
  img{
    width: 100%;
  }
}
</style>